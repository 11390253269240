
import Vue from "vue";
import ProfilesList from "@/components/subviews/profiles/profiles-list.vue";
import ProfilesMaintenance from "@/components/subviews/profiles/profiles-maintenance.vue";
import IProfile from "@/lib/interfaces/profile";

export default Vue.extend({
  name: "Profiles",

  components: { ProfilesList, ProfilesMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      profile: {} as IProfile,
    };
  },
});
