
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IProfile from "@/lib/interfaces/profile";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },
  name: "ProfilesList",

  props: {
    profile: {
      type: Object as PropType<IProfile>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      profiles: new Array<IProfile>(),

      headers: [
        { text: "Nome", value: "nome", align: "start" },
        {
          text: "Status",
          value: "cDesativado",
          sortable: false,
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getProfiles().then();
    },

    options: {
      deep: true,
      handler() {
        this.getProfiles().then();
      },
    },
  },

  computed: {
    cProfiles(): Array<Record<string, unknown>> {
      return this.profiles.map((profile) => ({
        ...profile,
        cDesativado: profile.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openPermissions(id: string) {
      this.$router.push({ name: "profile-permissions", params: { id } });
    },

    async openProfile(id: string) {
      const profile = this.profiles.find((profile) => profile.id === id);
      this.$emit("update:profile", { ...profile });
    },

    async getProfiles() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("profile.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.profiles = rsp.getTable("perfil")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
